import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaGoogle, FaStar, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import * as S from '../styles/Home.styles';

// Bilder importieren
import example1 from '../assets/images/example.webp';
import example2 from '../assets/images/example2.webp';
import example3 from '../assets/images/example3.webp';
import logoOG from '../assets/images/logoOGWhite.webp';
import brImage from '../assets/images/Standorte/BR/br2.webp';
import mureckImage from '../assets/images/Standorte/Mureck/IMG_0691.webp';
import brille1 from '../assets/images/Brillen/Brille1.webp';
import brille2 from '../assets/images/Brillen/Brille2.webp';
import brille3 from '../assets/images/Brillen/Brille3.webp';
import brille4 from '../assets/images/Brillen/Brille4.webp';
import brille5 from '../assets/images/Brillen/Brille5.webp';

// Typen
type Location = {
  name: string;
  hours: { day: string; hours: string }[];
  phone: string;
  email: string;
};

const locations: Record<string, Location> = {
  radkersburg: {
    name: 'Bad Radkersburg',
    hours: [
      { day: 'Montag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Dienstag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Mittwoch', hours: '8:30 - 12:00' },
      { day: 'Donnerstag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Freitag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Samstag', hours: '9:00 - 12:00' },
      { day: 'Sonntag', hours: 'geschlossen' }
    ],
    phone: '+43 3476 200 36',
    email: 'radkersburg@optik-geissler.at'
  },
  mureck: {
    name: 'Mureck',
    hours: [
      { day: 'Montag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Dienstag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Mittwoch', hours: '8:30 - 12:30' },
      { day: 'Donnerstag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Freitag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Samstag', hours: 'geschlossen' },
      { day: 'Sonntag', hours: 'geschlossen' }
    ],
    phone: '+43 3472 301 64',
    email: 'mureck@optik-geissler.at'
  }
};

const Home: React.FC = () => {
  const [activeImage, setActiveImage] = React.useState(1);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setActiveImage(prev => (prev === 0 ? 1 : 0));
    }, 5000); // Switch every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const scrollToHours = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default anchor behavior
    
    console.log("ScrollToHours called");
    const hoursSection = document.getElementById('hours');
    
    if (hoursSection) {
      const yOffset = -80; // Navbar height offset
      const y = hoursSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      window.scrollTo({
        top: y,
        behavior: 'smooth' // Explicitly set smooth for this in-page navigation
      });
    }
  };

  // All testimonials data
  const testimonials = [
    {
      stars: 5,
      text: "Top Optiker, Tolle Beratung, sehr gut",
      name: "Nikolaus A."
    },
    
    {
      stars: 5,
      text: "Sehr gute Beratung, perfekter Service. Sehr zu empfehlen!",
      name: "Brigita S."
    },
    {
      stars: 5,
      text: "Top Beratung und sehr freundliches Personal:) Sehr empfehlenswert...",
      name: "Nadine G."
    },
    {
      stars: 5,
      text: "Tolle Auswahl, kompetente Beratung und mega Service",
      name: "Stefan S."
    },
    {
      stars: 5,
      text: "Top Beratung!!! Hat Zeit für die Kunden!!",
      name: "Silvia F."
    },

    {
      stars: 5,
      text: "Anfahrt von Wien!!! zahlt sich aus. Schönste Fassung, beste Qualität, hervorragende Bedienung. Danke.",
      name: "Silvia B."
    },
    {
      stars: 5,
      text: "Tolle beratung schöne auswahl alles top🤌jeder zeit wieder eine kaufen würde.lg",
      name: "Grinschgl M."
    },
    {
      stars: 5,
      text: "Top Beratung, überaus nettes Personal und super Auswahl!",
      name: "Sabine K."
    },

    // Add more testimonials as needed
  ];

  const [activeSlide, setActiveSlide] = useState(0);
  const [direction, setDirection] = useState('forward');
  const testimonialsPerSlide = 3;
  
  // Create pages of testimonials - each page will have 3 testimonials
  const testimonialPages = [];
  for (let i = 0; i < testimonials.length; i += testimonialsPerSlide) {
    testimonialPages.push(testimonials.slice(i, i + testimonialsPerSlide));
  }

  // Auto-rotate slides
  useEffect(() => {
    const interval = setInterval(() => {
      handleNextSlide();
    }, 8000);
    
    return () => clearInterval(interval);
  }, [activeSlide]);
  
  const handleNextSlide = () => {
    setDirection('forward');
    setActiveSlide((prev) => (prev + 1) % testimonialPages.length);
  };
  
  const handlePrevSlide = () => {
    setDirection('backward');
    setActiveSlide((prev) => (prev === 0 ? testimonialPages.length - 1 : prev - 1));
  };

  const handleDotClick = (index: number) => {
    setDirection(index > activeSlide ? 'forward' : 'backward');
    setActiveSlide(index);
  };

  return (
    <S.Container>
      <Helmet>
        <title>Optik Geissler - Brillen & Sehtests in Bad Radkersburg und Mureck</title>
        <meta name="description" content="Ihr Spezialist für Brillen, Sehtests und Reparaturen in Bad Radkersburg und Mureck. Große Auswahl an modernen und klassischen Brillen in entspannter Atmosphäre." />
        <meta name="keywords" content="Optiker, Brillen, Sehtests, Bad Radkersburg, Mureck, Optik Geissler, Kontaktlinsen, Brillenreparatur" />
        <meta property="og:title" content="Optik Geissler - Ihr Spezialist für Brillen und Sehtests" />
        <meta property="og:description" content="Entdecken Sie modernste Brillenmode und professionelle Beratung in entspannter Atmosphäre in Bad Radkersburg und Mureck." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://optik-geissler.at" />
        <meta property="og:image" content={logoOG} />
        <meta property="og:locale" content="de_AT" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Optik Geissler - Brillen & Sehtests" />
        <meta name="twitter:description" content="Entdecken Sie moderne Brillenmode und professionelle Beratung in Bad Radkersburg und Mureck." />
        <meta name="twitter:image" content={logoOG} />
        <link rel="canonical" href="https://optik-geissler.at" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Optik Geissler",
              "url": "https://optik-geissler.at",
              "logo": "https://optik-geissler.at/logo.webp",
              "sameAs": ["https://www.google.com/maps/place/Optik+Geissler"],
              "contactPoint": [
                {
                  "@type": "ContactPoint",
                  "telephone": "+43 3476 2440",
                  "contactType": "customer service",
                  "areaServed": "Bad Radkersburg",
                  "availableLanguage": ["German"]
                },
                {
                  "@type": "ContactPoint",
                  "telephone": "+43 3472 301 64",
                  "contactType": "customer service",
                  "areaServed": "Mureck",
                  "availableLanguage": ["German"]
                }
              ],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "",
                "addressLocality": "Bad Radkersburg",
                "postalCode": "",
                "addressCountry": "AT"
              }
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "Optik Geissler",
              "image": "${logoOG}",
              "priceRange": "€€",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bad Radkersburg",
                "addressCountry": "Austria"
              },
              "telephone": "+43 3476 2440",
              "openingHoursSpecification": [
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": ["Monday", "Tuesday", "Thursday", "Friday"],
                  "opens": "08:30",
                  "closes": "17:00"
                },
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": ["Wednesday"],
                  "opens": "08:30",
                  "closes": "12:00"
                },
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": ["Saturday"],
                  "opens": "09:00",
                  "closes": "12:00"
                },
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": ["Sunday"],
                  "closes": "00:00",
                  "opens": "00:00"
                }
              ]
            }
          `}
        </script>
      </Helmet>
      <S.HeroSection>
        <S.HeroImage>
          <div className={`image-container ${activeImage === 0 ? 'active' : ''}`}>
            <img src={brImage} alt="Bad Radkersburg Location" />
            <S.LocationIndicator className={activeImage === 0 ? 'active' : ''}>
              <FaMapMarkerAlt /> Standort: Bad Radkersburg
            </S.LocationIndicator>
          </div>
          <div className={`image-container ${activeImage === 1 ? 'active' : ''}`}>
            <img src={mureckImage} alt="Mureck Location" />
            <S.LocationIndicator className={activeImage === 1 ? 'active' : ''}>
              <FaMapMarkerAlt /> Standort: Mureck
            </S.LocationIndicator>
          </div>
        </S.HeroImage>
        <S.HeroContent>
          <S.Logo src={logoOG} alt="Optik Geissler Logo" />
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Ihre Augen.<br />
            Unsere Expertise.
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Entdecken Sie modernste Brillenmode und professionelle Beratung in entspannter Atmosphäre.
          </motion.p>
          <S.ButtonGroup>
            <S.LinkButton to="/contact">Termin vereinbaren</S.LinkButton>
            <S.AnchorButton href="#hours" onClick={scrollToHours}>Öffnungszeiten</S.AnchorButton>
          </S.ButtonGroup>
        </S.HeroContent>
      </S.HeroSection>

      <S.ServicesSection>
        <S.ServicesContainer>
          <S.SectionTitleContainer>
            <S.SectionTitle>Unsere Leistungen</S.SectionTitle>
          </S.SectionTitleContainer>
          <S.ServicesDescription>
            Wir bieten Ihnen ein umfassendes Angebot an optischen Dienstleistungen
          </S.ServicesDescription>
          <S.ServicesGrid>
            <S.ServiceCard>
              <S.ServiceImage className="service-image">
                <img src={example1} alt="Brillen" />
              </S.ServiceImage>
              <S.ServiceContent>
                <S.ServiceTitle>Brillen</S.ServiceTitle>
                <p>Große Auswahl an modernen und klassischen Brillen für jeden Geschmack und Bedarf.</p>
                <S.ServiceFeatures>
                  <li>Modische Brillenfassungen</li>
                  <li>Hochwertige Markenbrillen</li>
                  <li>Individuelle Anpassung</li>
                </S.ServiceFeatures>
                <S.LearnMoreLink to="/services">Mehr erfahren →</S.LearnMoreLink>
              </S.ServiceContent>
            </S.ServiceCard>

            <S.ServiceCard>
              <S.ServiceImage className="service-image">
                <img src={example2} alt="Sehtests" />
              </S.ServiceImage>
              <S.ServiceContent>
                <S.ServiceTitle>Sehtests</S.ServiceTitle>
                <p>Professionelle Sehtests mit modernster Technologie für präzise Ergebnisse.</p>
                <S.ServiceFeatures>
                  <li>Computergestützte Analyse</li>
                  <li>Ausführliche Beratung</li>
                  <li>Kostenloser Sehtest</li>
                </S.ServiceFeatures>
                <S.LearnMoreLink to="/services">Mehr erfahren →</S.LearnMoreLink>
              </S.ServiceContent>
            </S.ServiceCard>

            <S.ServiceCard>
              <S.ServiceImage className="service-image">
                <img src={example3} alt="Kontaktlinsen" />
              </S.ServiceImage>
              <S.ServiceContent>
                <S.ServiceTitle>Reparatur</S.ServiceTitle>
                <p>Professionelle Reparatur und Anpassung von Brillen und optischen Geräten.</p>
                <S.ServiceFeatures>
                  <li>Schnelle Notfallreparaturen</li>
                  <li>Fachgerechte Brillenanpassung</li>
                  <li>Ersatzteilservice</li>
                </S.ServiceFeatures>
                <S.LearnMoreLink to="/services">Mehr erfahren →</S.LearnMoreLink>
              </S.ServiceContent>
            </S.ServiceCard>
          </S.ServicesGrid>
        </S.ServicesContainer>
      </S.ServicesSection>

      <S.InfoSection id="hours">
        <S.SectionTitleContainer>
          <S.SectionTitle>Öffnungszeiten</S.SectionTitle>
        </S.SectionTitleContainer>
        <S.LocationGrid>
          {Object.entries(locations).map(([key, location]) => (
            <S.LocationCard key={key}>
              <S.LocationTitle>{location.name}</S.LocationTitle>
              {location.hours.map((hour, index) => (
                <S.HourRow key={index}>
                  <span>{hour.day}</span>
                  <span>{hour.hours}</span>
                </S.HourRow>
              ))}
              <S.ContactInfo>
                <S.ContactRow>
                  <FaPhone /> 
                  <a href={`tel:${location.phone.replace(/\s/g, '')}`}>{location.phone}</a>
                </S.ContactRow>
                <S.ContactRow>
                  <FaEnvelope /> 
                  <a href={`mailto:${location.email}`}>{location.email}</a>
                </S.ContactRow>
              </S.ContactInfo>
            </S.LocationCard>
          ))}
        </S.LocationGrid>
      </S.InfoSection>
      <S.BrandsSection>
        <S.SectionTitleContainer>
          <S.SectionTitle>Unsere Marken</S.SectionTitle>
        </S.SectionTitleContainer>
        <S.BrandsDescription>
          Von exklusiven Markenbrillen bis hin zu trendy, erschwinglichen Modellen für Jung und Alt - unser vielfältiges Sortiment erfüllt jeden Wunsch und Stil.
        </S.BrandsDescription>
        <S.CarouselContainer>
          <S.CarouselTrack>
            {/* First set of images */}
            <S.CarouselImage src={brille1} alt="Brille 1" />
            <S.CarouselImage src={brille2} alt="Brille 2" />
            <S.CarouselImage src={brille3} alt="Brille 3" />
            <S.CarouselImage src={brille4} alt="Brille 4" />
            <S.CarouselImage src={brille5} alt="Brille 5" />
            {/* Duplicate set for seamless loop */}
            <S.CarouselImage src={brille1} alt="Brille 1" />
            <S.CarouselImage src={brille2} alt="Brille 2" />
            <S.CarouselImage src={brille3} alt="Brille 3" />
            <S.CarouselImage src={brille4} alt="Brille 4" />
            <S.CarouselImage src={brille5} alt="Brille 5" />
          </S.CarouselTrack>
        </S.CarouselContainer>
      </S.BrandsSection>

      <S.TestimonialsSection>
        <S.TestimonialsContainer>
          <S.SectionTitleContainer>
            <S.SectionTitle>Das sagen unsere Kunden</S.SectionTitle>
          </S.SectionTitleContainer>
          
          {/* Testimonials Carousel */}
          <S.TestimonialsCarouselContainer>
            <S.CarouselArrow onClick={handlePrevSlide} className="prev-arrow">
              <FaChevronLeft />
            </S.CarouselArrow>
            
            <S.TestimonialsGrid key={`${activeSlide}-${direction}`} $activeSlide={activeSlide} $direction={direction}>
              {testimonialPages[activeSlide]?.map((testimonial, index) => (
                <S.TestimonialCard 
                  key={`testimonial-${activeSlide}-${index}`}
                  style={{ 
                    '--card-index': index,
                    '--direction': direction === 'forward' ? '1' : '-1'
                  } as React.CSSProperties}
                >
                  <div className="stars">
                    {[...Array(testimonial.stars)].map((_, i) => (
                      <FaStar key={i} />
                    ))}
                  </div>
                  <p className="review-text">"{testimonial.text}"</p>
                  <p className="reviewer-name">{testimonial.name}</p>
                  <div className="review-source">
                    <FaGoogle /> Google Rezension
                  </div>
                </S.TestimonialCard>
              ))}
            </S.TestimonialsGrid>
            
            <S.CarouselArrow onClick={handleNextSlide} className="next-arrow">
              <FaChevronRight />
            </S.CarouselArrow>
          </S.TestimonialsCarouselContainer>
          
          <S.CarouselIndicators>
            {Array.from({ length: testimonialPages.length }).map((_, index) => (
              <S.CarouselDot 
                key={index} 
                active={index === activeSlide} 
                onClick={() => handleDotClick(index)}
              />
            ))}
          </S.CarouselIndicators>
        </S.TestimonialsContainer>
      </S.TestimonialsSection>
    </S.Container>
  );
};

export default Home;
