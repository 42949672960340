import React from 'react';
import {
  Container,
  MainContent,
  HeroSection,
  HeroContent,
  ServiceSection,
  SectionHeader,
  ServicesGrid,
  ServiceCard,
  ServiceCardImage,
  ServiceCardContent,
  SpecialistSection,
  SpecialistItem,
  SpecialistLogo,
  LocationContainer,
  LocationRow,
  LocationDivider
} from '../styles/Services.styles';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';

// Import images
import exampleImage from '../assets/images/example.webp';
import exampleImage2 from '../assets/images/pexels-paggiarofrancesco-1627639.webp';
import exampleImage3 from '../assets/images/pexels-kseniachernaya-5715905.webp';
import hansatonLogo from '../assets/images/Logos/hansaton.webp';
import beckseiLogo from '../assets/images/Logos/Becksei.webp';

// Extend existing styled components for mobile responsiveness
const MobileSpecialistItem = styled(SpecialistItem)`
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.1rem;
      line-height: 1.3;
      word-wrap: break-word;
      hyphens: auto;
      width: 85%; /* Ensure heading doesn't extend too far right */
      margin-right: 15%; /* Make space for icons in the corner */
      margin-top: 0.5rem;
    }
    
    p {
      font-size: 0.95rem;
    }
  }
`;

const MobileSpecialistLogo = styled(SpecialistLogo)`
  @media (max-width: 768px) {
    max-width: 120px;
    margin-bottom: 1rem;
    
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

const MobileLocationContainer = styled(LocationContainer)`
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const MobileLocationRow = styled(LocationRow)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    
    h4 {
      margin-bottom: 0.5rem;
    }
    
    .time-container {
      margin-left: 0;
    }
  }
`;

const Services = () => {
  return (
    <Container>
      {/* Add global styles for hover effects */}
      <Global
        styles={css`
          .service-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
          }
          
          .service-card:hover .service-card-image img {
            transform: scale(1.05);
          }
        `}
      />
      
      <MainContent>
        {/* Hero Section */}
        <HeroSection>
          <img src={exampleImage} alt="Serviceleistungen Hero" />
          <HeroContent>
            <h1>Unsere Serviceleistungen</h1>
            <p>
              Bei Optik Geissler erhalten Sie qualitativ hochwertige Dienstleistungen rund um Ihre Brillen und Hörgeräte.
              Entdecken Sie unsere umfassenden Angebote und lassen Sie sich von unseren erfahrenen Spezialisten beraten.
            </p>
          </HeroContent>
        </HeroSection>

        {/* Brillenservice Section */}
        <ServiceSection>
          <SectionHeader>
            <h2>Brillenservice</h2>
            <p>
              Von der professionellen Beratung bis zur individuellen Anpassung - wir sorgen dafür, 
              dass Ihre Brille perfekt zu Ihnen passt und Sie optimal sehen können.
            </p>
          </SectionHeader>

          <ServicesGrid>
            <ServiceCard className="service-card">
              <ServiceCardImage className="service-card-image">
                <img src={exampleImage2} alt="Brillenkollektion" />
              </ServiceCardImage>
              <ServiceCardContent>
                <h3>Unsere Brillenkollektion</h3>
                <ul>
                  <li>Große Auswahl an Designer- und Markenbrillen</li>
                  <li>Moderne und klassische Modelle für jeden Geschmack</li>
                  <li>Hochwertige Sonnenbrillen und Sportbrillen</li>
                  <li>Kinderbrillen in vielen Varianten</li>
                </ul>
              </ServiceCardContent>
            </ServiceCard>

            <ServiceCard className="service-card">
              <ServiceCardImage className="service-card-image">
                <img src={exampleImage3} alt="Individuelle Brillenanpassung" />
              </ServiceCardImage>
              <ServiceCardContent>
                <h3>Individuelle Anpassung</h3>
                <ul>
                  <li>Ausführliche Sehberatung und Sehtest</li>
                  <li>Professionelle Brillenanpassung</li>
                  <li>Brillenreinigung und Reparaturservice</li>
                  <li>Zustellung im Raum Bad Radkersburg</li>
                </ul>
              </ServiceCardContent>
            </ServiceCard>
          </ServicesGrid>
        </ServiceSection>

        {/* Specialists Section */}
        <SpecialistSection>
          <SectionHeader>
            <h2>Spezialleistungen</h2>
            <p>
              Neben unserem umfassenden Brillenservice bieten wir Ihnen weitere spezialisierte
              Dienstleistungen für Ihr optimales Hör- und Seherlebnis an.
            </p>
          </SectionHeader>

          <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
            <MobileSpecialistItem>
              <MobileSpecialistLogo>
                <img src={hansatonLogo} alt="Hansaton Logo" />
              </MobileSpecialistLogo>
              <h3>Hansaton Hörgeräte Beratung</h3>
              <p>
                Wir bieten kostenloses Hörgeräteservice und umfassende Hörberatungen durch unsere zertifizierten 
                Hansaton-Experten an. Vereinbaren Sie einen Termin für einen unverbindlichen Hörtest und lassen 
                Sie sich zu den neuesten Hörtechnologien beraten.
              </p>
              <MobileLocationContainer>
                <MobileLocationRow>
                  <h4>Bad Radkersburg</h4>
                  <div className="time-container">
                    <p className="day">Termine laut Ausschreibung im Geschäft</p>
                  </div>
                </MobileLocationRow>
                <LocationDivider />
                <MobileLocationRow>
                  <h4>Mureck</h4>
                  <div className="time-container">
                    <p className="day">Termine laut Ausschreibung im Geschäft</p>
                  </div>
                </MobileLocationRow>
              </MobileLocationContainer>
            </MobileSpecialistItem>

            <MobileSpecialistItem>
              <MobileSpecialistLogo>
                <img src={beckseiLogo} alt="Orthopädie Becksei Logo" />
              </MobileSpecialistLogo>
              <h3>Orthopädiefachwerkstätte Becskei</h3>
              <p>
                Die Firma Becskei führt bei uns im Haus regelmäßig professionelle orthopädische Anpassungen 
                und Beratungen durch. Nutzen Sie diesen Service für eine kompetente Beratung zu orthopädischen Hilfsmitteln.
              </p>
              <MobileLocationContainer>
                <MobileLocationRow>
                  <h4>Bad Radkersburg</h4>
                  <div className="time-container">
                    <p className="day">Jeden Dienstag</p>
                    <p className="time">11:00 - 12:00 Uhr</p>
                  </div>
                </MobileLocationRow>
              </MobileLocationContainer>
            </MobileSpecialistItem>
          </div>
        </SpecialistSection>
      </MainContent>
    </Container>
  );
};

export default Services;