import React from 'react';
import styled from '@emotion/styled';
import { FaTimes, FaPhone, FaEnvelope } from 'react-icons/fa';
import { theme } from '../styles/theme';

type Location = {
  name: string;
  hours: { day: string; hours: string }[];
  phone: string;
  email: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  location: Location;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2.5rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
  min-width: 300px;

  h2 {
    margin-top: 0;
    margin-bottom: 2rem;
    color: ${theme.colors.primary};
    font-size: 1.75rem;
    border-bottom: 2px solid ${theme.colors.primary};
    padding-bottom: 0.5rem;
    padding-right: 2rem;
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 95%;
    
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      padding-right: 1.5rem;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: ${theme.colors.text};
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
  min-width: 2rem;
  min-height: 2rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    color: ${theme.colors.primary};
  }
  
  @media (max-width: 768px) {
    top: 0.8rem;
    right: 0.8rem;
    
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

const HourRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  span {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    padding: 0.4rem 0;
    padding-right: 0.4rem;
    display: grid;
    grid-template-columns: 90px minmax(0, 1fr);
    width: 100%;
    column-gap: 0.5rem;
    align-items: center;
    
    span {
      font-size: 0.75rem;
      line-height: 1.2;
      white-space: nowrap;
    }
    
    span:first-of-type {
      font-weight: 500;
    }
    
    span:last-child {
      max-width: 100%;
    }
  }
`;

const ContactInfo = styled.div`
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 2px solid #eee;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  
  @media (max-width: 768px) {
    margin-top: 1.2rem;
    padding-top: 1.2rem;
    gap: 0.5rem;
  }
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  svg {
    color: ${theme.colors.primary};
    min-width: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: ${theme.colors.primary};
    }
  }
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    
    svg {
      font-size: 0.9rem;
    }
    
    a {
      word-break: break-word;
    }
  }
`;

const OpeningHoursModal: React.FC<Props> = ({ isOpen, onClose, location }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        <h2>Öffnungszeiten {location.name}</h2>
        
        {location.hours.map((hour, index) => (
          <HourRow key={index}>
            <span>{hour.day}</span>
            <span>{hour.hours}</span>
          </HourRow>
        ))}
        
        <ContactInfo>
          <ContactRow>
            <FaPhone />
            <a href={`tel:${location.phone.replace(/\s/g, '')}`}>{location.phone}</a>
          </ContactRow>
          <ContactRow>
            <FaEnvelope />
            <a href={`mailto:${location.email}`}>{location.email}</a>
          </ContactRow>
        </ContactInfo>
      </ModalContent>
    </ModalOverlay>
  );
};

export default OpeningHoursModal;
