import styled from '@emotion/styled';
import { theme } from './theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Remove any margin/padding here to avoid white space */
`;

export const MainContent = styled.main`
  flex: 1;
  /* Remove the fixed margin-top that was causing the issue */
  display: flex;
  flex-direction: column;
`;

export const HeroSection = styled.section`
  position: relative;
  background-color: #f9f9f9;
  /* Use padding-top to account for navbar height */
  padding: 0;
  text-align: center;
  overflow: hidden;
  /* Set a more reasonable height instead of using vh units */
  height: auto;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin: 0 auto;
  /* Add specific padding values to control the space */
  padding: 8rem 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 768px) {
    padding: 7rem 1.5rem 3rem;
  }
  
  h1 {
    font-size: 3rem;
    color: white;
    margin-bottom: 1.5rem;
    font-weight: 500;
    
    @media (max-width: 768px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
  
  p {
    font-size: 1.2rem;
    color: white;
    margin-bottom: 0;
    
    @media (max-width: 768px) {
      font-size: 0.95rem;
      line-height: 1.4;
    }
  }
`;

export const ServiceSection = styled.section`
  padding: 4rem 2rem;
  background-color: white;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  
  h2 {
    font-size: 2.5rem;
    color: ${theme.colors.primary};
    margin-bottom: 1rem;
    font-weight: 300;
    position: relative;
    display: inline-block;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 3px;
      background-color: ${theme.colors.primary};
    }
    
    @media (max-width: 768px) {
      font-size: 1.8rem;
      margin-bottom: 0.8rem;
      
      &:after {
        bottom: -8px;
        height: 2px;
      }
    }
  }
  
  p {
    font-size: 1.1rem;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    
    @media (max-width: 768px) {
      font-size: 0.95rem;
      line-height: 1.5;
      padding: 0 0.5rem;
    }
  }
  
  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const ServiceCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
`;

export const ServiceCardImage = styled.div`
  height: 250px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  @media (max-width: 768px) {
    height: 200px;
  }
`;

export const ServiceCardContent = styled.div`
  padding: 2rem;
  
  h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 500;
    position: relative;
    display: inline-block;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${theme.colors.primary};
    }
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
    }
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 1.5rem 0;
    
    li {
      position: relative;
      padding-left: 1.8rem;
      margin-bottom: 0.8rem;
      font-size: 1.05rem;
      color: #444;
      
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${theme.colors.primary};
      }
      
      @media (max-width: 768px) {
        font-size: 0.95rem;
        margin-bottom: 0.6rem;
        padding-left: 1.5rem;
        
        &:before {
          top: 8px;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

export const SpecialistSection = styled.section`
  background-color: #f9f9f9;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const LocationContainer = styled.div`
  margin-top: 1.5rem;
  border-top: 1px solid #eee;
  padding-top: 1.5rem;
  
  @media (max-width: 768px) {
    margin-top: 1rem;
    padding-top: 1rem;
  }
`;

export const LocationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  h4 {
    font-size: 1.2rem;
    color: #333;
    font-weight: 500;
    margin: 0;
  }
  
  .time-container {
    text-align: right;
    
    .day {
      font-weight: 500;
      margin-bottom: 0.2rem;
      color: #555;
    }
    
    .time {
      color: ${theme.colors.primary};
      font-weight: 500;
    }
  }
  
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    
    h4 {
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
    }
    
    .time-container {
      text-align: left;
      
      .day, .time {
        font-size: 0.95rem;
      }
    }
  }
`;

export const LocationDivider = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 1rem 0;
`;

export const SpecialistItem = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;
  position: relative;
  
  h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 500;
    position: relative;
    display: inline-block;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${theme.colors.primary};
    }
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
    }
  }
  
  p {
    margin: 1rem 0;
    font-size: 1.05rem;
    color: #444;
    line-height: 1.6;
    
    @media (max-width: 768px) {
      font-size: 0.95rem;
      line-height: 1.5;
    }
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const SpecialistLogo = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 100px;
  height: 50px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    width: 80px;
    height: 40px;
    top: 1.5rem;
    right: 1.5rem;
  }
`;