import styled from '@emotion/styled';
import { theme } from './theme';
import { Link } from 'react-router-dom';

export const NotFoundContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  margin-top: -85px; // Compensate for navbar height
  
  @media (max-width: 768px) {
    margin-top: -70px;
    padding: 1.5rem;
  }
`;

export const NotFoundContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const ErrorCode = styled.h1`
  font-size: 8rem;
  color: ${theme.colors.primary};
  margin: 0;
  font-weight: 300;
  line-height: 1;
  
  @media (max-width: 768px) {
    font-size: 6rem;
  }
`;

export const ErrorTitle = styled.h2`
  font-size: 2rem;
  color: ${theme.colors.text};
  margin: 1rem 0;
  font-weight: 400;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 1rem 0 2rem;
  line-height: 1.6;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    margin: 1rem 0 1.5rem;
  }
`;

export const HomeLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: ${theme.colors.primary};
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    background: #d63600;
  }
  
  @media (max-width: 768px) {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }
`; 