import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import NotFound from './pages/NotFound';
// We'll add other components back once they're implemented
import './App.css';

// ScrollToTop component to reset scroll position when navigating
const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  // This will run before the component mounts
  useEffect(() => {
    // Use non-smooth scrolling for immediate reset
    window.scrollTo({ top: 0, behavior: 'auto' });
    
    // Reset any body/html styles that might affect scrolling
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    
    console.log("Route changed to:", pathname, "- scroll reset instantly");
  }, [pathname]);
  
  return null;
}

// We need to wrap the app with Router, then use ScrollToTop inside
const AppContent = () => {
  const location = useLocation();
  
  // Reset scroll position when location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;
