import React from 'react';
import styled from '@emotion/styled';
import { FaFacebookF, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import { theme } from '../styles/theme';

const FooterContainer = styled.footer`
  background: #000000;
  color: white;
  padding: 3rem 2rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const LocationInfo = styled.div`
  h3 {
    color: ${theme.colors.primary};
    margin-bottom: 1rem;
  }

  .contact-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    a {
      color: white;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: ${theme.colors.primary};
      }
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

const SocialLinks = styled.div`
  h3 {
    color: ${theme.colors.primary};
    margin-bottom: 1rem;
  }

  .social-link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    text-decoration: none;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: ${theme.colors.primary};
    }
  }

  @media (max-width: 768px) {
    .social-link {
      justify-content: center;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LocationInfo>
          <h3>Bad Radkersburg</h3>
          <div className="contact-item">
            <FaMapMarkerAlt /> Hauptplatz 20, 8490 Bad Radkersburg
          </div>
          <div className="contact-item">
            <FaPhone /> <a href="tel:+4334762036">+43 3476 200 36</a>
          </div>
          <div className="contact-item">
            <FaEnvelope /> <a href="mailto:radkersburg@optik-geissler.at">radkersburg@optik-geissler.at</a>
          </div>
        </LocationInfo>

        <LocationInfo>
          <h3>Mureck</h3>
          <div className="contact-item">
            <FaMapMarkerAlt /> Quellengasse 1, 8480 Mureck
          </div>
          <div className="contact-item">
            <FaPhone /> <a href="tel:+4334723164">+43 3472 301 64</a>
          </div>
          <div className="contact-item">
            <FaEnvelope /> <a href="mailto:mureck@optik-geissler.at">mureck@optik-geissler.at</a>
          </div>
        </LocationInfo>

        <SocialLinks>
          <h3>Social Media</h3>
          <a 
            href="https://www.facebook.com/people/Optik-Geissler/100089576538713/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="social-link"
          >
            <FaFacebookF /> Facebook
          </a>
        </SocialLinks>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
