import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import { theme } from '../styles/theme';

const MainImageContainer = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden;
  border: 1px solid #eee;
  background: white;
  cursor: zoom-in;

  &:hover .controls {
    opacity: 1;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
  pointer-events: none;

  > button {
    pointer-events: auto;
  }
`;

const ControlButton = styled.button`
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(2px);

  &:hover {
    background: rgba(255, 255, 255, 0.95);
    transform: scale(1.05);
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin: 0.5rem 0 2rem 0;
`;

const ThumbnailImage = styled.div<{ active?: boolean }>`
  aspect-ratio: 16/9;
  overflow: hidden;
  cursor: pointer;
  opacity: ${props => props.active ? 1 : 0.7};
  transition: all 0.3s ease;
  border: 1px solid ${props => props.active ? theme.colors.primary : '#eee'};
  background: white;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const FullscreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 2rem;

  .controls {
    opacity: 1;
    background: none;
    padding: 0 2rem;
    pointer-events: auto;
  }
`;

const FullscreenImage = styled.img`
  max-width: 95vw;
  max-height: 95vh;
  object-fit: contain;
  cursor: zoom-out;
  user-select: none;
`;

const CloseButton = styled(ControlButton)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10000;

  &:hover {
    background: white;
  }
`;

interface ImageCarouselProps {
  images: string[];
  currentIndex: number;
  onIndexChange: (index: number) => void;
  altText: string;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  currentIndex,
  onIndexChange,
  altText,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handlePrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    onIndexChange((currentIndex - 1 + images.length) % images.length);
  };

  const handleNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    onIndexChange((currentIndex + 1) % images.length);
  };

  const toggleFullscreen = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setIsFullscreen(!isFullscreen);
  };

  // Handle escape key to exit fullscreen
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isFullscreen) {
        setIsFullscreen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [isFullscreen]);

  return (
    <>
      <MainImageContainer onClick={(e) => toggleFullscreen(e)}>
        <MainImage
          src={images[currentIndex]}
          alt={`${altText} - Hauptbild`}
        />
        <Controls className="controls">
          <ControlButton onClick={handlePrevious} aria-label="Vorheriges Bild">
            <FaChevronLeft />
          </ControlButton>
          <ControlButton onClick={handleNext} aria-label="Nächstes Bild">
            <FaChevronRight />
          </ControlButton>
        </Controls>
      </MainImageContainer>

      <ThumbnailGrid>
        {images.map((image, index) => (
          <ThumbnailImage
            key={index}
            active={index === currentIndex}
            onClick={() => onIndexChange(index)}
          >
            <img src={image} alt={`${altText} ${index + 1}`} />
          </ThumbnailImage>
        ))}
      </ThumbnailGrid>

      {isFullscreen && (
        <FullscreenOverlay onClick={toggleFullscreen}>
          <FullscreenImage
            src={images[currentIndex]}
            alt={`${altText} - Vollbild`}
            onClick={(e) => e.stopPropagation()}
          />
          <CloseButton onClick={toggleFullscreen} aria-label="Schließen">
            <FaTimes />
          </CloseButton>
          <Controls className="controls">
            <ControlButton onClick={handlePrevious} aria-label="Vorheriges Bild">
              <FaChevronLeft />
            </ControlButton>
            <ControlButton onClick={handleNext} aria-label="Nächstes Bild">
              <FaChevronRight />
            </ControlButton>
          </Controls>
        </FullscreenOverlay>
      )}
    </>
  );
};

export default ImageCarousel;
