import React, { useState } from 'react';
import { useForm } from '@formspree/react';
import { FaMapMarkerAlt, FaPhone, FaClock, FaEnvelope } from 'react-icons/fa';
import {
  ContactContainer,
  ContactContent,
  ContactInfo,
  LocationBlock,
  InfoItem,
  ContactForm,
  FormGroup,
  SubmitButton,
  SuccessMessage,
  LoadingOverlay
} from '../styles/Contact.styles';
import OpeningHoursModal from '../components/OpeningHoursModal';

type LocationType = {
  name: string;
  address: string;
  phone: string;
  email: string;
  hours: Array<{ day: string; hours: string }>;
};

const Contact = () => {
  // Create two separate form handlers for each location
  const [radkersburgForm, handleRadkersburgSubmit] = useForm("xdkallyy");
  const [mureckForm, handleMureckSubmit] = useForm("[Mureck]");
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    location: '',
    name: '',
    contact: '',
    message: ''
  });

  const locations: Record<string, LocationType> = {
    radkersburg: {
      name: "Bad Radkersburg",
      address: "Hauptplatz 5, 8490 Bad Radkersburg",
      phone: "+43 3476 200 36",
      email: "radkersburg@optik-geissler.at",
      hours: [
        { day: 'Montag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
        { day: 'Dienstag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
        { day: 'Mittwoch', hours: '8:30 - 12:00' },
        { day: 'Donnerstag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
        { day: 'Freitag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
        { day: 'Samstag', hours: '9:00 - 12:00' },
        { day: 'Sonntag', hours: 'geschlossen' }
      ]
    },
    mureck: {
      name: "Mureck",
      address: "Quellengasse 1, 8480 Mureck",
      phone: "+43 3472 301 64",
      email: "mureck@optik-geissler.at",
      hours: [
        { day: 'Montag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
        { day: 'Dienstag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
        { day: 'Mittwoch', hours: '8:30 - 12:30' },
        { day: 'Donnerstag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
        { day: 'Freitag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
        { day: 'Samstag', hours: 'geschlossen' },
        { day: 'Sonntag', hours: 'geschlossen' }
      ]
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Choose the correct submit handler based on location
    const submitHandler = formData.location === 'radkersburg' 
      ? handleRadkersburgSubmit 
      : handleMureckSubmit;

    // Submit the form
    await submitHandler(formData);
  };

  // Determine if the form was submitted successfully
  const isSuccess = formData.location === 'radkersburg' 
    ? radkersburgForm.succeeded 
    : mureckForm.succeeded;

  return (
    <ContactContainer>
      <ContactContent>
        <ContactInfo>
          <h2>Kontakt</h2>
          {Object.entries(locations).map(([key, location]) => (
            <LocationBlock key={location.name}>
              <h3>{location.name}</h3>
              <InfoItem>
                <FaMapMarkerAlt />
                <span>{location.address}</span>
              </InfoItem>
              <InfoItem>
                <FaPhone />
                <a href={`tel:${location.phone.replace(/\s/g, '')}`}>{location.phone}</a>
              </InfoItem>
              <InfoItem>
                <FaEnvelope />
                <a href={`mailto:${location.email}`}>{location.email}</a>
              </InfoItem>
              <InfoItem className="clickable" onClick={() => setSelectedLocation(key)}>
                <FaClock />
                <span>Öffnungszeiten anzeigen</span>
              </InfoItem>
            </LocationBlock>
          ))}
        </ContactInfo>

        <ContactForm onSubmit={handleSubmit} style={{ position: 'relative' }}>
          {isSuccess ? (
            <SuccessMessage>
              <h3>Vielen Dank für Ihre Nachricht!</h3>
              <p>Wir werden uns so bald wie möglich bei Ihnen melden.</p>
            </SuccessMessage>
          ) : (
            <>
              <h2>Nachricht senden</h2>
              <FormGroup>
                <label htmlFor="location">Standort</label>
                <select
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled selected>Bitte wählen Sie einen Standort</option>
                  <option value="radkersburg">Bad Radkersburg</option>
                  <option value="mureck">Mureck</option>
                </select>
              </FormGroup>
              <FormGroup>
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  placeholder="Ihr Name"
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="contact">Email oder Telefon</label>
                <input
                  id="contact"
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  required
                  placeholder="Ihre Email-Adresse oder Telefonnummer"
                  pattern="^(?:\+?[0-9\s-()]{9,}|[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})$"
                  title="Bitte geben Sie eine gültige Email-Adresse oder Telefonnummer ein"
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="message">Nachricht</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  placeholder="Ihre Nachricht"
                />
              </FormGroup>
              <SubmitButton 
                type="submit" 
                disabled={isSubmitting || radkersburgForm.submitting || mureckForm.submitting}
              >
                {(isSubmitting || radkersburgForm.submitting || mureckForm.submitting) 
                  ? "Wird gesendet..." 
                  : "Senden"}
              </SubmitButton>
              {isSubmitting && (
                <LoadingOverlay>
                  <div>Wird gesendet...</div>
                </LoadingOverlay>
              )}
            </>
          )}
        </ContactForm>
      </ContactContent>

      {selectedLocation && (
        <OpeningHoursModal
          isOpen={true}
          onClose={() => setSelectedLocation(null)}
          location={locations[selectedLocation]}
        />
      )}
    </ContactContainer>
  );
};

export default Contact;
