export const theme = {
  colors: {
    primary: '#fa4204', // New vibrant orange
    secondary: '#F5F5F5', // Light gray
    accent: '#F5E6D3', // Soft beige
    text: '#000000', // Pure black
    white: '#FFFFFF',
    background: '#ffffff',
  },
  fonts: {
    primary: "'Roboto', sans-serif",
    secondary: "'Playfair Display', serif",
  },
  underline: {
    height: '2px',
    color: '#fa4204', // Same as primary
    spacing: '8px', // Space between text and underline
  },
  glassMorphism: {
    background: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(12px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
  },
} as const;
