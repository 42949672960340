import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { theme } from '../styles/theme';
import logoOG from '../assets/images/logoOGWhite.png';

const Nav = styled.nav<{ scrolled: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem 2rem;
  margin-top: 0;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;

  @media (max-width: 768px) {
    padding: 1rem;
    top: -1px; /* Fix for the white pixel issue on mobile */
  }
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const MenuButton = styled.button<{ isOpen: boolean }>`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1000;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 1.8rem;
  }

  div {
    width: 2rem;
    height: 0.125rem;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-of-type {
      transform: ${({ isOpen }) => isOpen ? 'rotate(45deg) translate(0px, -1px)' : 'rotate(0)'};
    }

    &:nth-of-type(2) {
      opacity: ${({ isOpen }) => isOpen ? '0' : '1'};
      transform: ${({ isOpen }) => isOpen ? 'translateX(20px)' : 'translateX(0)'};
    }

    &:nth-of-type(3) {
      transform: ${({ isOpen }) => isOpen ? 'rotate(-45deg) translate(-5px, 4px)' : 'rotate(0)'};
    }
  }
`;

const NavLinks = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(100%)'};
    opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
    z-index: 999;
    overflow-y: auto;
    pointer-events: ${({ isOpen }) => isOpen ? 'auto' : 'none'};
  }
`;

const NavLink = styled(Link)<{ $isActive?: boolean }>`
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  position: relative;
  padding: 0.5rem 0;
  display: inline-block;
  margin: 0 1rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.primary};
    opacity: ${props => props.$isActive ? '1' : '0'};
    transition: opacity 0.3s ease;
  }

  &:hover:after {
    opacity: 1;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 0.75rem 0;
    color: white;
    text-align: center;
    width: auto;
    display: inline-block;
    
    &:after {
      height: 3px;
      width: 100%;
    }
  }
`;

const MobileNavHeader = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    position: relative;
    padding: 1rem 0 1.5rem 0;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 1rem;
  }
`;

const MobileNavLinksContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
  }
`;

const MobileLogo = styled.img`
  height: 40px;
  width: auto;
`;

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrolled = scrollY > 50;
      setIsScrolled(scrolled);
    };

    // Initial check
    handleScroll();
    
    // Add event listener
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      // Just prevent scrolling without changing position
      document.body.style.overflow = 'hidden';
    } else {
      // Restore scrolling without changing position
      document.body.style.overflow = '';
    }
    
    return () => {
      // Always ensure scrolling is enabled when component unmounts
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  // Dynamically compute navbar background styles
  const navbarStyle = {
    backgroundColor: isHomePage 
      ? (isScrolled ? 'rgba(0, 0, 0, 0.95)' : 'transparent') 
      : 'rgba(0, 0, 0, 0.95)',
    backdropFilter: isHomePage 
      ? (isScrolled ? 'blur(10px)' : 'none') 
      : 'blur(10px)'
  };

  return (
    <Nav scrolled={isScrolled} style={navbarStyle}>
      <NavContainer>
        <LogoContainer to="/">
          <Logo src={logoOG} alt="Optik Geissler" />
        </LogoContainer>
        
        <MenuButton 
          isOpen={isOpen} 
          onClick={(e) => {
            // Just toggle menu state without affecting scroll
            setIsOpen(!isOpen);
          }}
          aria-label="Toggle Menu"
        >
          <div />
          <div />
          <div />
        </MenuButton>

        <NavLinks isOpen={isOpen}>
          <MobileNavHeader>
            <MobileLogo src={logoOG} alt="Optik Geissler" />
          </MobileNavHeader>
          <MobileNavLinksContainer>
            <NavLink 
              to="/" 
              $isActive={location.pathname === '/'}
              onClick={() => setIsOpen(false)}
            >
              Home
            </NavLink>
            <NavLink 
              to="/about" 
              $isActive={location.pathname === '/about'}
              onClick={() => setIsOpen(false)}
            >
              Über uns
            </NavLink>
            <NavLink 
              to="/services" 
              $isActive={location.pathname === '/services'}
              onClick={() => setIsOpen(false)}
            >
              Leistungen
            </NavLink>
            <NavLink 
              to="/contact" 
              $isActive={location.pathname === '/contact'}
              onClick={() => setIsOpen(false)}
            >
              Kontakt
            </NavLink>
          </MobileNavLinksContainer>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

export default Navbar;
