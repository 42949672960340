import styled from '@emotion/styled';
import { theme } from './theme';

export const ContactContainer = styled.div`
  margin-top: 80px;
  padding: 4rem 2rem;
  background: #fff;
  
  @media (max-width: 768px) {
    margin-top: 70px; /* Adjusted for mobile navbar height */
    padding: 2rem 1rem;
  }
`;

export const ContactContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

export const ContactInfo = styled.div`
  h2 {
    font-size: 2.4rem;
    color: ${theme.colors.text};
    margin-bottom: 2rem;
    font-weight: 300;
    position: relative;
    padding-bottom: 0.5rem;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${theme.colors.primary};
    }
    
    @media (max-width: 768px) {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  }
`;

export const LocationBlock = styled.div`
  margin-bottom: 2.5rem;
  padding: 2rem;
  background: white;
  border: 1px solid #eee;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.4rem;
    color: ${theme.colors.text};
    margin-bottom: 1rem;
    font-weight: 300;
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    
    h3 {
      font-size: 1.3rem;
      margin-bottom: 0.8rem;
    }
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: ${theme.colors.text};

  svg {
    color: ${theme.colors.primary};
    font-size: 1.2rem;
    flex-shrink: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${theme.colors.primary};
    }
  }

  &.clickable {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: ${theme.colors.primary};
    }
  }
  
  @media (max-width: 768px) {
    gap: 0.8rem;
    margin-bottom: 0.8rem;
    font-size: 0.95rem;
    
    svg {
      font-size: 1.1rem;
    }
    
    span, a {
      word-break: break-word;
    }
  }
`;

export const ContactForm = styled.form`
  background: white;
  padding: 2rem;
  border: 1px solid #eee;

  h2 {
    font-size: 2rem;
    color: ${theme.colors.text};
    margin-bottom: 2rem;
    font-weight: 300;
    position: relative;
    padding-bottom: 0.5rem;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${theme.colors.primary};
    }
    
    @media (max-width: 768px) {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: ${theme.colors.text};
    font-weight: 500;

    &::after {
      content: '*';
      color: ${theme.colors.primary};
      margin-left: 4px;
    }
    
    @media (max-width: 768px) {
      font-size: 0.95rem;
      margin-bottom: 0.4rem;
    }
  }

  input, textarea, select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: inherit;
    font-size: 1rem;
    color: ${theme.colors.text};
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: ${theme.colors.primary};
    }

    &::placeholder {
      color: #999;
    }
    
    @media (max-width: 768px) {
      padding: 0.7rem;
      font-size: 0.95rem;
    }
  }

  select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1em;
    padding-right: 2.5rem;
    cursor: pointer;

    option {
      color: ${theme.colors.text};
    }

    &:invalid {
      color: #999;
    }

    option:not([disabled]) {
      color: ${theme.colors.text};
    }
    
    option:first-of-type {
      color: #999;
      font-style: italic;
    }
    
    option[value=""] {
      color: #999;
      font-style: italic;
    }
  }

  textarea {
    min-height: 150px;
    resize: vertical;
    
    @media (max-width: 768px) {
      min-height: 120px;
    }
  }
  
  @media (max-width: 768px) {
    margin-bottom: 1.2rem;
  }
`;

export const SubmitButton = styled.button`
  background: ${theme.colors.primary};
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 0.8rem;
    font-size: 0.95rem;
  }
`;

export const SuccessMessage = styled.div`
  text-align: center;
  padding: 2rem;
  background: ${theme.colors.background};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    color: ${theme.colors.primary};
    margin-bottom: 1rem;
  }

  p {
    color: ${theme.colors.text};
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.3rem;
      margin-bottom: 0.8rem;
    }
    
    p {
      font-size: 0.95rem;
    }
  }
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;
