import styled from '@emotion/styled';
import { theme } from './theme';

export const OwnerSection = styled.div`
  padding: 2rem 2rem 2rem;
  background: #f9f9f9;
  border-bottom: 1px solid #eee;
  
  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

export const AboutSection = styled.div`
  padding: 2rem;
`;

export const LocationContainer = styled.div`
  padding: 2rem;
  background: #fff;
  
  &:nth-of-type(odd) {
    background: #f9f9f9;
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

// Updated for more flexibility
export const LocationContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  
  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

// New component for the top section with location info and images
export const LocationHeader = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  
  @media (max-width: 968px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

// New card-based container for the location information
export const LocationInfoCard = styled.div`
  flex: 1;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 968px) {
    width: 100%;
    padding: 1.5rem;
  }
`;

// Primary content area for the location
export const LocationImagesCard = styled.div`
  flex: 1.5;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  padding: 2rem;
  
  @media (max-width: 968px) {
    width: 100%;
    padding: 1.5rem;
  }
`;

// New section for team and tour content
export const LocationFeatures = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  
  @media (max-width: 968px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export const TeamContainer = styled.div`
  flex: 1;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  padding: 2rem;
  
  h3 {
    font-size: 1.5rem;
    color: ${theme.colors.text};
    margin-bottom: 1.5rem;
    font-weight: 400;
    position: relative;
    padding-bottom: 0.5rem;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: ${theme.colors.primary};
    }
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.3rem;
      margin-bottom: 1.2rem;
    }
  }
`;

export const TeamGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: flex-start;
  
  @media (max-width: 768px) {
    gap: 1rem;
    justify-content: center;
  }
`;

export const TeamMemberCard = styled.div`
  width: 140px;
  background: white;
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  @media (max-width: 768px) {
    width: 120px;
  }
`;

export const TeamMemberImage = styled.div`
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 20%;
  }
`;

export const TeamMemberContent = styled.div`
  padding: 0.75rem;
  text-align: center;
  background: white;

  h4 {
    font-size: 0.9rem;
    color: ${theme.colors.text};
    margin: 0;
    font-weight: 500;
  }
  
  .location-note {
    font-size: 0.75rem;
    color: ${theme.colors.primary};
    margin-top: 0.25rem;
    font-style: italic;
  }
`;

export const TourContainer = styled.div`
  flex: 1.5;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  padding: 2rem;
  
  h3 {
    font-size: 1.5rem;
    color: ${theme.colors.text};
    margin-bottom: 1.5rem;
    font-weight: 400;
    position: relative;
    padding-bottom: 0.5rem;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: ${theme.colors.primary};
    }
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.3rem;
      margin-bottom: 1.2rem;
    }
    
    p {
      font-size: 0.95rem;
    }
  }
`;

export const VirtualTourContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid #eee;
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-self: start;
  min-width: 0; /* Prevent flex item from overflowing */
`;

export const TeamSection = styled.div`
  background: white;
  width: 100%;
  min-width: 0; /* Prevent flex item from overflowing */
`;

export const LocationInfo = styled.div`
  h2 {
    font-size: 2rem;
    color: ${theme.colors.text};
    margin-bottom: 1.5rem;
    font-weight: 300;
    position: relative;
    padding-bottom: 0.5rem;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: ${theme.colors.primary};
    }
  }
`;

export const LocationDescription = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }
`;

export const ContactInfoWrapper = styled.div`
  margin-top: auto;
  background: white;
  border-radius: 4px;

  .info-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 0;
    color: ${theme.colors.text};
    
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    svg {
      color: ${theme.colors.primary};
      font-size: 1.2rem;
      flex-shrink: 0;
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: ${theme.colors.primary};
      }
    }

    &.clickable {
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: ${theme.colors.primary};
      }
    }
  }
  
  @media (max-width: 768px) {
    .info-item {
      padding: 0.6rem 0;
      font-size: 0.9rem;
      
      svg {
        font-size: 1rem;
      }
    }
  }
`;

export const LocationImages = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LocationMainImage = styled.div`
  aspect-ratio: 16/9;
  overflow: hidden;
  border: 1px solid #eee;
  background: white;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const LocationImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin: 0.5rem 0 2rem 0;
`;

export const LocationImage = styled.div<{ active?: boolean }>`
  aspect-ratio: 16/9;
  overflow: hidden;
  cursor: pointer;
  opacity: ${props => props.active ? 1 : 0.7};
  transition: all 0.3s ease;
  border: 1px solid ${props => props.active ? theme.colors.primary : '#eee'};
  background: white;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const OwnerContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 4rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
  }
`;

export const OwnerImage = styled.div`
  width: 250px;
  overflow: hidden;
  border: 1px solid #eee;
  background: white;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);

  img {
    width: 100%;
    height: 333px;
    object-fit: cover;
  }

  .owner-name {
    padding: 0.75rem;
    text-align: center;
    border-top: 1px solid #eee;
    color: #333;
    font-weight: 500;
    background: white;
    margin-top: -5px; /* Fix for white line gap */
  }
  
  @media (max-width: 768px) {
    width: 200px;
    
    img {
      height: 266px;
    }
  }
`;

export const OwnerNameBox = styled.div`
  background: white;
  border: 1px solid #eee;
  padding: 0.5rem;
  margin-top: 0.5rem;
  text-align: center;
  width: 250px;
  color: #333;
  font-weight: 500;
`;

export const OwnerInfo = styled.div`
  flex: 1;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  padding: 2rem;

  h2 {
    font-size: 2.4rem;
    color: ${theme.colors.text};
    margin-bottom: 0.75rem;
    font-weight: 300;
  }

  h3 {
    font-size: 1.3rem;
    color: ${theme.colors.primary};
    margin-bottom: 1.25rem;
    font-weight: 300;
  }

  p {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.25rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  @media (max-width: 768px) {
    text-align: left;
    padding: 1.5rem;
    
    h2 {
      font-size: 1.8rem;
    }
    
    h3 {
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }
    
    p {
      font-size: 0.95rem;
      margin-bottom: 1rem;
    }
  }
`;

export const LocationButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    margin-top: 1.5rem;
  }
`;

export const LocationButton = styled.button`
  padding: 1rem 2rem;
  background-color: white;
  color: ${theme.colors.primary};
  border: 2px solid ${theme.colors.primary};
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${theme.colors.primary};
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(250, 66, 4, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
`;

// Page container with margin to account for fixed navbar
export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  margin-top: 85px; /* Same as in Services.styles.ts for consistency */
  
  @media (max-width: 768px) {
    margin-top: 70px; /* Adjust for smaller navbar on mobile */
  }
`;
