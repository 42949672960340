import styled from '@emotion/styled';
import { theme } from '../styles/theme';
import { Link, LinkProps } from 'react-router-dom';

// Define interface for LinkButton props
interface LinkButtonProps extends LinkProps {
  primary?: boolean;
}

// Define interface for AnchorButton props
interface AnchorButtonProps {
  primary?: boolean;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export type { LinkButtonProps, AnchorButtonProps };

export const Container = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`;

export const Section = styled.section`
  padding: 4rem 2rem;
  position: relative;
`;

export const HeroSection = styled(Section)`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 0;
  background: #000000;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    min-height: auto;
    grid-template-rows: 40vh auto;
    height: auto;
  }
`;

export const HeroImage = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;

  .image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.active {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    height: 40vh;
    
    .image-container {
      position: absolute;
      height: 40vh;
    }
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  color: white;

  h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    line-height: 1.1;
    font-weight: 300;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 3rem;
    opacity: 0.8;
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    padding-bottom: 3rem;
    justify-content: flex-start;
    
    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    
    p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
      line-height: 1.4;
    }
  }
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    width: 150px;
    margin-bottom: 1rem;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
  }
`;

export const Button = styled.button`
  display: inline-block;
  padding: 1rem 3rem;
  border: 1px solid white;
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  background: transparent;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background: white;
    color: black;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    padding: 0.875rem 1.5rem;
    font-size: 1rem;
  }
`;

export const LinkButton = styled(Link)<LinkButtonProps>`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: ${props => props.primary ? theme.colors.primary : 'transparent'};
  color: white;
  border: 2px solid ${props => props.primary ? theme.colors.primary : 'white'};
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.primary ? '#d63600' : 'rgba(255, 255, 255, 0.1)'};
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
    text-align: center;
  }
`;

export const AnchorButton = styled.a<AnchorButtonProps>`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: ${props => props.primary ? theme.colors.primary : 'transparent'};
  color: white;
  border: 2px solid ${props => props.primary ? theme.colors.primary : 'white'};
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  min-width: 180px;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.primary ? '#d63600' : 'rgba(255, 255, 255, 0.1)'};
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
    text-align: center;
    width: 100%;
    min-width: unset;
  }
`;

export const ServicesSection = styled(Section)`
  background: white;
  padding: 4rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

export const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const ServicesHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  h2 {
    font-size: 2.5rem;
    color: ${theme.colors.text};
    margin-bottom: 1rem;
    font-weight: 300;
  }

  p {
    color: #666;
    font-size: 1.1rem;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 2rem;
    
    h2 {
      font-size: 1.8rem;
    }
    
    p {
      font-size: 1rem;
    }
  }
`;

export const ServicesDescription = styled.p`
  color: #666;
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
  line-height: 1.6;
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }
`;

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const ServiceCard = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);

    .service-image img {
      transform: scale(1.1);
    }
  }
`;

export const ServiceImage = styled.div`
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  @media (max-width: 768px) {
    height: 180px;
  }
`;

export const ServiceContent = styled.div`
  padding: 2rem;
  background: white;

  h3 {
    font-size: 1.5rem;
    color: ${theme.colors.text};
    margin-bottom: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      color: ${theme.colors.text};
      font-size: 1.2rem;
    }
  }

  p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.3rem;
      margin-bottom: 0.8rem;
    }
    
    p {
      font-size: 0.95rem;
      margin-bottom: 1rem;
    }
  }
`;

export const ServiceFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    margin-bottom: 0.5rem;

    svg {
      color: ${theme.colors.primary};
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    li {
      font-size: 0.9rem;
      margin-bottom: 0.4rem;
    }
  }
`;

export const LearnMoreLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${theme.colors.primary};
  text-decoration: none;
  font-weight: 500;
  margin-top: 1rem;
  transition: gap 0.3s ease;

  &:hover {
    gap: 0.8rem;
  }
`;

export const InfoSection = styled(Section)`
  background: #f8f8f8;
  padding: 4rem 4rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

export const LocationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

export const LocationCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: ${theme.colors.text};
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
  }
`;

export const HourRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  span {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    padding: 0.4rem 0;
    padding-right: 0.5rem;
    display: grid;
    grid-template-columns: 110px minmax(0, 1fr);
    width: 100%;
    column-gap: 0.5rem;
    align-items: center;
    
    span {
      font-size: 0.83rem;
      line-height: 1.2;
      white-space: nowrap;
    }
    
    span:first-of-type {
      font-weight: 500;
    }
    
    span:last-child {
      max-width: 100%;
    }
  }
`;

export const ContactInfo = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  
  @media (max-width: 768px) {
    margin-top: 1rem;
    gap: 0.5rem;
  }
`;

export const ContactRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  svg {
    color: ${theme.colors.primary};
    min-width: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover {
      color: ${theme.colors.primary};
    }
  }
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    
    svg {
      font-size: 0.9rem;
    }
    
    a {
      word-break: break-word;
    }
  }
`;

export const BrandsSection = styled(Section)`
  background: white;
  text-align: center;
  padding: 4rem 0;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 3rem 0;
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 2rem 0;
  margin: 0 auto;
  max-width: 1400px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, white, transparent);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, white, transparent);
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem 0;
    
    &::before,
    &::after {
      width: 100px;
    }
  }
`;

export const CarouselTrack = styled.div`
  display: flex;
  gap: 2rem;
  animation: scroll 40s linear infinite;
  width: fit-content;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  &:hover {
    animation-play-state: paused;
  }
  
  @media (max-width: 768px) {
    gap: 1.5rem;
    animation: scroll 30s linear infinite;
  }
`;

export const CarouselImage = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    width: 200px;
    height: 140px;
  }
`;

export const BrandsDescription = styled.p`
  color: #666;
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto 3rem;
  padding: 0 2rem;
  line-height: 1.6;
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    margin: 0 auto 2rem;
    padding: 0 1.5rem;
    line-height: 1.5;
  }
`;

export const BrandsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 4rem;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  img {
    max-width: 120px;
    height: auto;
    filter: grayscale(100%);
    transition: filter 0.3s ease;

    &:hover {
      filter: grayscale(0%);
    }
  }
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
    
    img {
      max-width: 80px;
    }
  }
`;

export const SectionTitleContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: ${theme.colors.primary};
  font-weight: 300;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  color: ${theme.colors.primary};
  margin-bottom: 1rem;
  font-weight: 500;
  position: relative;
  padding-bottom: ${theme.underline.spacing};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${theme.underline.height};
    background-color: ${theme.underline.color};
  }
`;

export const LocationTitle = styled.h3`
  font-size: 1.5rem;
  color: ${theme.colors.primary};
  margin-bottom: 1.5rem;
  font-weight: 500;
  position: relative;
  padding-bottom: ${theme.underline.spacing};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${theme.underline.height};
    background-color: ${theme.underline.color};
  }
`;

export const BrandTitle = styled.div`
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #666;
`;

export const BrandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const BrandImageWrapper = styled.div`
  width: 180px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const BrandsNote = styled.p`
  text-align: center;
  font-size: 1rem;
  color: #666;
  margin-top: 3rem;
  font-style: italic;
`;

export const TestimonialsSection = styled(Section)`
  background: #f9f9f9;
  padding: 5rem 2rem;
  
  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

export const TestimonialsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const TestimonialsHeader = styled.h2`
  font-size: 2.5rem;
  color: ${theme.colors.text};
  margin-bottom: 3rem;
  font-weight: 300;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
`;

export const TestimonialsCarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 40px;
  min-height: 300px;
  
  @media (max-width: 768px) {
    min-height: 400px;
  }
  
  @media (max-width: 576px) {
    min-height: 500px;
  }
`;

export const TestimonialsTrack = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const TestimonialsGrid = styled.div<{ $activeSlide: number; $direction: string }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  opacity: 0;
  transform: translateX(${props => props.$direction === 'forward' ? '20px' : '-20px'});
  animation: slideIn 0.5s forwards;
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(${props => props.$direction === 'forward' ? '20px' : '-20px'});
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

export const CarouselArrow = styled.button`
  background: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s ease;

  &:hover {
    background: #f8f8f8;
    transform: translateY(-50%) scale(1.05);
  }

  &.prev-arrow {
    left: 0;
  }

  &.next-arrow {
    right: 0;
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

export const CarouselIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 8px;
`;

export const CarouselDot = styled.button<{ active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.active ? '#007bff' : '#ccc'};
  border: none;
  padding: 0;
  cursor: pointer;
  transition: background 0.3s ease;
  
  &:hover {
    background: ${props => props.active ? '#007bff' : '#aaa'};
  }
`;

export const TestimonialCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: calc(var(--card-index, 0) * 0.1s);

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(calc(10px * var(--direction, 1)));
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:hover {
    transform: translateY(-5px);
  }

  .stars {
    color: #FFC107;
    margin-bottom: 1rem;
    display: flex;
    gap: 4px;
  }

  .review-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    line-height: 1.6;
    color: ${theme.colors.text};
    margin-bottom: 1.5rem;
  }

  .reviewer-name {
    font-weight: 500;
    color: ${theme.colors.text};
    margin-bottom: 0.5rem;
  }

  .review-source {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 0.9rem;

    svg {
      font-size: 1.2rem;
      color: ${theme.colors.primary};
    }
  }
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    
    .stars {
      margin-bottom: 0.8rem;
      
      svg {
        font-size: 0.9rem;
      }
    }
    
    .review-text {
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
    
    .reviewer-name {
      font-size: 0.95rem;
    }
    
    .review-source {
      font-size: 0.8rem;
      
      svg {
        font-size: 1rem;
      }
    }
  }
`;

export const TestimonialText = styled.p`
  font-family: ${theme.fonts.secondary};
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: ${theme.colors.text};
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
`;

export const TestimonialAuthor = styled.div`
  font-family: ${theme.fonts.primary};
  font-weight: 500;
  color: ${theme.colors.text};
  margin-bottom: 0.3rem;
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
  }
`;

export const TestimonialSource = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${theme.colors.text};
  font-size: 0.9rem;
  
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const StarRating = styled.div`
  color: #fbbc04;
  display: flex;
  gap: 2px;
  margin-bottom: 0.5rem;
`;

export const LocationIndicator = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;
  transition: opacity 1s ease-in-out;

  &.active {
    opacity: 1;
  }

  svg {
    color: ${theme.colors.primary};
  }
`;
