import React from 'react';
import { FaHome } from 'react-icons/fa';
import {
  NotFoundContainer,
  NotFoundContent,
  ErrorCode,
  ErrorTitle,
  ErrorMessage,
  HomeLink
} from '../styles/NotFound.styles';

const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundContent>
        <ErrorCode>404</ErrorCode>
        <ErrorTitle>Seite nicht gefunden</ErrorTitle>
        <ErrorMessage>
          Entschuldigung, die von Ihnen gesuchte Seite existiert nicht. 
          Möglicherweise wurde sie verschoben oder gelöscht.
        </ErrorMessage>
        <HomeLink to="/">
          <FaHome /> Zurück zur Startseite
        </HomeLink>
      </NotFoundContent>
    </NotFoundContainer>
  );
};

export default NotFound; 