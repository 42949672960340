import React, { useState, useEffect } from 'react';
import { 
  LocationContainer, 
  LocationContent, 
  LocationHeader,
  LocationInfoCard,
  LocationImagesCard,
  LocationFeatures,
  TeamContainer,
  TeamGrid,
  TeamMemberCard, 
  TeamMemberImage, 
  TeamMemberContent, 
  TourContainer,
  OwnerSection, 
  OwnerContent, 
  OwnerImage, 
  OwnerInfo, 
  VirtualTourContainer, 
  LocationButtonsContainer, 
  LocationButton, 
  ContactInfoWrapper, 
  LocationInfo, 
  LocationImages,
  PageContainer
} from '../styles/About.styles';
import { LocationDescription } from '../styles/About.styles';
import { FaMapMarkerAlt, FaPhone, FaClock, FaEnvelope, FaWalking } from 'react-icons/fa';
import OpeningHoursModal from '../components/OpeningHoursModal';
import ImageCarousel from '../components/ImageCarousel';

// Owner Image
import ownerImage from '../assets/images/Mitarbeiter/Geissler.webp';

// Location Images - Bad Radkersburg
import brImage1 from '../assets/images/Standorte/BR/br1.webp';
import brImage2 from '../assets/images/Standorte/BR/br2.webp';
import brImage3 from '../assets/images/Standorte/BR/br3.webp';

// Location Images - Mureck
import mureckImage1 from '../assets/images/Standorte/Mureck/IMG_0691.webp';
import mureckImage2 from '../assets/images/Standorte/Mureck/IMG_0687.webp';
import mureckImage3 from '../assets/images/Standorte/Mureck/IMG_0689.webp';

// Team Images - Bad Radkersburg
import elfriede from '../assets/images/Mitarbeiter/BR/Elfriede Schinko.webp';

// Team Images - Mureck
import karoline from '../assets/images/Mitarbeiter/Mureck/Karolline König.webp';
import larissa from '../assets/images/Mitarbeiter/Mureck/Larissa Grauberger.webp';

type Location = {
  name: string;
  description: string;
  hours: { day: string; hours: string }[];
  phone: string;
  email: string;
  address: string;
  images: string[];
};

const locations: Record<string, Location> = {
  radkersburg: {
    name: 'Bad Radkersburg',
    description: 'Unser Geschäft in Bad Radkersburg bietet Ihnen modernste Augenoptik in historischem Ambiente. Genießen Sie unsere persönliche Beratung in entspannter Atmosphäre.',
    hours: [
      { day: 'Montag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Dienstag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Mittwoch', hours: '8:30 - 12:00' },
      { day: 'Donnerstag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Freitag', hours: '8:30 - 12:00 und 14:00 - 17:00' },
      { day: 'Samstag', hours: '9:00 - 12:00' },
      { day: 'Sonntag', hours: 'geschlossen' }
    ],
    phone: '+43 3476 200 36',
    email: 'radkersburg@optik-geissler.at',
    address: 'Hauptplatz 5, 8490 Bad Radkersburg',
    images: [brImage1, brImage2, brImage3]
  },
  mureck: {
    name: 'Mureck',
    description: 'In unserem Geschäft in Mureck erwartet Sie modernste Technik und eine große Auswahl an Brillen und Kontaktlinsen. Unser Team berät Sie gerne bei der Wahl Ihrer neuen Brille.',
    hours: [
      { day: 'Montag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Dienstag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Mittwoch', hours: '8:30 - 12:30' },
      { day: 'Donnerstag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Freitag', hours: '8:30 - 12:30 und 13:30 - 17:00' },
      { day: 'Samstag', hours: 'geschlossen' },
      { day: 'Sonntag', hours: 'geschlossen' }
    ],
    phone: '+43 3472 301 64',
    email: 'mureck@optik-geissler.at',
    address: 'Quellengasse 1, 8480 Mureck',
    images: [mureckImage1, mureckImage2, mureckImage3]
  }
};

const About = () => {
  const [currentBRImage, setCurrentBRImage] = useState(0);
  const [currentMureckImage, setCurrentMureckImage] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

  const brImages = [brImage1, brImage2, brImage3];
  const mureckImages = [mureckImage1, mureckImage2, mureckImage3];

  const scrollToLocation = (locationId: string) => {
    console.log("ScrollToLocation called for:", locationId);
    const locationSection = document.getElementById(locationId);
    
    if (locationSection) {
      const yOffset = -120; // Larger offset for About page sections
      const y = locationSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
      
      window.scrollTo({
        top: y,
        behavior: 'smooth' // Explicitly set smooth for this in-page navigation
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBRImage((prev) => (prev + 1) % brImages.length);
      setCurrentMureckImage((prev) => (prev + 1) % mureckImages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <PageContainer>
      {/* Owner/Welcome Section */}
      <OwnerSection>
        <OwnerContent>
          <OwnerImage>
            <img src={ownerImage} alt="Optik Geissler - Inhaber" />
            <div className="owner-name">Christian Geissler</div>
          </OwnerImage>
          <OwnerInfo>
            <h2>Willkommen bei Optik Geissler</h2>
            <h3>Ihr Experte für moderne Augenoptik</h3>
            <p>
              Mit über 30 Jahren Erfahrung in der Augenoptik steht Optik Geissler für höchste Qualität 
              und persönliche Beratung. In unseren zwei Standorten in der Südoststeiermark bieten wir 
              Ihnen modernste Technik und eine große Auswahl an Brillen und Kontaktlinsen.
            </p>
            <p>
              Unser erfahrenes Team berät Sie gerne bei der Wahl Ihrer neuen Brille und steht Ihnen 
              mit Fachwissen und Engagement zur Seite.
            </p>
            <LocationButtonsContainer>
              <LocationButton onClick={() => scrollToLocation('mureck')}>
                <FaMapMarkerAlt /> Mureck
              </LocationButton>
              <LocationButton onClick={() => scrollToLocation('bad-radkersburg')}>
                <FaMapMarkerAlt /> Bad Radkersburg
              </LocationButton>
            </LocationButtonsContainer>
          </OwnerInfo>
        </OwnerContent>
      </OwnerSection>

      {/* Mureck Location */}
      <LocationContainer id="mureck">
        <LocationContent>
          {/* Top section with info and images */}
          <LocationHeader>
            {/* Left: Location Info */}
            <LocationInfoCard>
              <LocationInfo>
                <h2>{locations.mureck.name}</h2>
                <LocationDescription>
                  {locations.mureck.description}
                </LocationDescription>
              </LocationInfo>
              
              <ContactInfoWrapper>
                <div className="info-item">
                  <FaMapMarkerAlt />
                  <span>{locations.mureck.address}</span>
                </div>
                <div className="info-item">
                  <FaPhone />
                  <a href={`tel:${locations.mureck.phone.replace(/\s/g, '')}`}>{locations.mureck.phone}</a>
                </div>
                <div className="info-item">
                  <FaEnvelope />
                  <a href={`mailto:${locations.mureck.email}`}>{locations.mureck.email}</a>
                </div>
                <div className="info-item clickable" onClick={() => setSelectedLocation('mureck')}>
                  <FaClock />
                  <span>Öffnungszeiten anzeigen</span>
                </div>
              </ContactInfoWrapper>
            </LocationInfoCard>

            {/* Right: Location Images */}
            <LocationImagesCard>
              <LocationImages>
                <ImageCarousel 
                  images={mureckImages}
                  currentIndex={currentMureckImage}
                  onIndexChange={setCurrentMureckImage}
                  altText="Optik Geissler Mureck"
                />
              </LocationImages>
            </LocationImagesCard>
          </LocationHeader>

          {/* Bottom section with team and 3D tour */}
          <LocationFeatures>
            {/* Left: Team Members */}
            <TeamContainer>
              <h3>Mitarbeiter</h3>
              <TeamGrid>
                <TeamMemberCard>
                  <TeamMemberImage>
                    <img src={karoline} alt="Karoline König" />
                  </TeamMemberImage>
                  <TeamMemberContent>
                    <h4>Karoline König</h4>
                  </TeamMemberContent>
                </TeamMemberCard>
                <TeamMemberCard>
                  <TeamMemberImage>
                    <img src={larissa} alt="Larissa Grauberger" />
                  </TeamMemberImage>
                  <TeamMemberContent>
                    <h4>Larissa Grauberger</h4>
                  </TeamMemberContent>
                </TeamMemberCard>
              </TeamGrid>
            </TeamContainer>

            {/* Right: 3D Tour */}
            <TourContainer>
              <h3>Virtueller Rundgang</h3>
              <VirtualTourContainer>
                <iframe 
                  src="https://3dbranchen.at/show/optik-geissler-mureck.html"
                  title="3D Rundgang Optik Geissler Mureck"
                  allow="fullscreen"
                  loading="lazy"
                />
              </VirtualTourContainer>
            </TourContainer>
          </LocationFeatures>
        </LocationContent>
      </LocationContainer>

      {/* Bad Radkersburg Location */}
      <LocationContainer id="bad-radkersburg">
        <LocationContent>
          {/* Top section with info and images */}
          <LocationHeader>
            {/* Left: Location Info */}
            <LocationInfoCard>
              <LocationInfo>
                <h2>{locations.radkersburg.name}</h2>
                <LocationDescription>
                  {locations.radkersburg.description}
                </LocationDescription>
              </LocationInfo>
              
              <ContactInfoWrapper>
                <div className="info-item">
                  <FaMapMarkerAlt />
                  <span>{locations.radkersburg.address}</span>
                </div>
                <div className="info-item">
                  <FaPhone />
                  <a href={`tel:${locations.radkersburg.phone.replace(/\s/g, '')}`}>{locations.radkersburg.phone}</a>
                </div>
                <div className="info-item">
                  <FaEnvelope />
                  <a href={`mailto:${locations.radkersburg.email}`}>{locations.radkersburg.email}</a>
                </div>
                <div className="info-item clickable" onClick={() => setSelectedLocation('radkersburg')}>
                  <FaClock />
                  <span>Öffnungszeiten anzeigen</span>
                </div>
              </ContactInfoWrapper>
            </LocationInfoCard>

            {/* Right: Location Images */}
            <LocationImagesCard>
              <LocationImages>
                <ImageCarousel 
                  images={brImages}
                  currentIndex={currentBRImage}
                  onIndexChange={setCurrentBRImage}
                  altText="Optik Geissler Bad Radkersburg"
                />
              </LocationImages>
            </LocationImagesCard>
          </LocationHeader>

          {/* Bottom section with team */}
          <LocationFeatures>
            {/* Team Members */}
            <TeamContainer>
              <h3>Mitarbeiter</h3>
              <TeamGrid>
                <TeamMemberCard>
                  <TeamMemberImage>
                    <img src={elfriede} alt="Elfriede Schinko" />
                  </TeamMemberImage>
                  <TeamMemberContent>
                    <h4>Elfriede Schinko</h4>
                  </TeamMemberContent>
                </TeamMemberCard>
                <TeamMemberCard>
                  <TeamMemberImage>
                    <img src={larissa} alt="Larissa Grauberger" />
                  </TeamMemberImage>
                  <TeamMemberContent>
                    <h4>Larissa Grauberger</h4>
                  </TeamMemberContent>
                </TeamMemberCard>
              </TeamGrid>
            </TeamContainer>
            
            {/* Placeholder for balancing layout - only for BR location */}
            <TourContainer>
              <h3>Historisches Ambiente</h3>
              <p>
                In unserem Geschäft in Bad Radkersburg erleben Sie moderne Augenoptik in historischem 
                Ambiente. Unser Geschäft befindet sich im malerischen Hauptplatz und vereint Tradition 
                mit hochmoderner Technik.
              </p>
              <p style={{ marginTop: '1rem' }}>
                Wir laden Sie herzlich ein, unsere Räumlichkeiten persönlich zu besuchen und sich von 
                unserer einzigartigen Atmosphäre überzeugen zu lassen.
              </p>
              <LocationButton 
                onClick={() => window.open('https://maps.app.goo.gl/9dsMM6mtmjLMWrdc9', '_blank')}
                style={{ marginTop: '1.5rem' }}
              >
                <FaWalking /> Besuchen Sie uns
              </LocationButton>
            </TourContainer>
          </LocationFeatures>
        </LocationContent>
      </LocationContainer>

      {/* Opening Hours Modal */}
      {selectedLocation && (
        <OpeningHoursModal
          isOpen={true}
          onClose={() => setSelectedLocation(null)}
          location={locations[selectedLocation]}
        />
      )}
    </PageContainer>
  );
};

export default About;
